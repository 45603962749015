import { decrypt } from "./others";

export const auth = () => !!decrypt(localStorage.user);

export const getRole = () => {
  const role = decrypt(localStorage.user);
  return role.role;
};
export const getPermission = () =>{
  const permission = decrypt(localStorage.user)
  return permission.permissions
}

export const getUserId = () => {
  const user = decrypt(localStorage.user);
  return user.user_id;
};
