import { configureStore } from "@reduxjs/toolkit";

// We'll use redux-logger just as an example of adding another middleware
import logger from "redux-logger";
import { folderPathReducer } from "./Reducers/folderPath.reducer";
//Import Reducers
import { countriesReducer } from "./Reducers/countries.reducers";
import notifiactionSlice from "./Reducers/notifiactionSlice";
import fetchNotificationsSlice from "./Reducers/fetchNotificationSlice";
import appDeviceSlice from "./Reducers/appDeviceSlice";
import searchResources from "../Components/MentorResources/searchResources";

const reducer = {
  countriesReducer,
  folderPathReducer,
  resources: searchResources,
  appDeviceData: appDeviceSlice,
  notifiactionData: notifiactionSlice,
  fetchNotificationsData: fetchNotificationsSlice
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [],
});

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together
