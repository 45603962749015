import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  token: "",
};

export const countriesReducer = createReducer(initialState, {
  INSERT_TOKEN: (state, action) => {
    state.token = action.data;
  },
});
