import React, { Component, Fragment, Suspense } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//Component Imports
import Authentication from "./Core/Authentication";
import Routes from "./Core/Routes";
import Loader from "./Core/Loader/Loader";
import { Spinner } from "react-bootstrap";
import SideNavigationBar from "./Components/SideNavbar/SideNavigationBar";
import { decrypt } from "./utils/others";
import { getRole } from "./utils/auth";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid } from "@material-ui/core";
import FirstClassFeedback from "./Components/ParentFeedback/FirstClassFeedback";
import SideNavbar from "./Components/SideNavbar/SideNavbar";

const SuccessPayment = React.lazy(() =>
  import("./Components/BootCamp/SuccessPayment/SucessPayment")
);
//Lazy Imports
const Login = React.lazy(() => import("./Components/Login/Login"));
const Demos = React.lazy(() => import("./Components/Demos/Demos"));
const MentorDashboardNew = React.lazy(() =>
  import("./Components/MentorDashboard/MentorDashboardNew")
);
const MentorResources = React.lazy(() =>
  import("./Components/MentorResources/MentorResources")
);

const Classes = React.lazy(() => import("./Components/Classes/Classes"));
const BootCamp = React.lazy(() =>
  import("./Components/BootCamp/BootCampRouter")
);
const Mentors = React.lazy(() => import("./Components/Mentors/Mentors"));
const ViewDetails = React.lazy(() =>
  import("./Components/Mentors/ViewDetails")
);
const ViewConversion = React.lazy(() =>
  import("./Components/Mentors/ViewConversion")
);
const Glimpse = React.lazy(() => import("./Components/Glimpse"));
const FeedbackForm = React.lazy(() =>
  import("./Components/Glimpse/FeedbackForm")
);
const TimeTable = React.lazy(() => import("./Components/TimeTable/TimeTable"));
const MentorProfilePage = React.lazy(() =>
  import("./Components/MentorProfilePage/MentorProfilePage")
);
const UserManagement = React.lazy(() =>
  import("./Components/UserManagement/UserManagement")
);
const StudentPortfolio = React.lazy(() =>
  import("./Components/StudentPortfolio/StudentPortfolio")
);
const BootCampLandingPage = React.lazy(() =>
  import("./Components/BootCamp/BootCampLandingPage/BootCampLandingPage")
);
const Courses = React.lazy(() => import("./Components/Courses/Courses"));
const Promotions = React.lazy(() =>
  import("./Components/Promotions/Promotions")
);
const AddOrUpdatePromotions = React.lazy(() =>
  import("./Components/Promotions/AddOrUpdatePromotions/AddOrUpdatePromotions")
);

const StudentProfileReport = React.lazy(() =>
  import("./Components/StudentProfileReport/StudentProfileReport")
);

const AffiliateDemos = React.lazy(() =>
  import("./Components/AffiliateDemos/AffiliateDemos")
);

const ZoomMeet = React.lazy(() => import("./Components/ZoomMeet/ZoomMeet"));
const LeaveZoom = React.lazy(() => import("./Components/ZoomMeet/LeaveZoom"));
const StudentManageMent = React.lazy(() =>
  import("./Components/StudentManagement/Index")
);

const SelectBatch = React.lazy(() =>
  import("./Components/SelectBatchPages/SelectBatchPage")
);

const Alchemy = React.lazy(() => import("./Components/Alchemy/Alchemy"));
const AlchemyFilePreview = React.lazy(() =>
  import("./Components/Alchemy/AlchemyFilePreview/AlchemyFilePreview")
);

const ParentFeedback = React.lazy(() =>
  import("./Components/ParentFeedback/ParentFeedback")
);

const SuccessFeedback = React.lazy(() =>
  import("./Components/ParentFeedback/SuccessFeedback")
);

const Slots = React.lazy(() => import("./Components/Slots/Slots"));

const Community = React.lazy(() => import("./Components/Community/Community"));

const StoreFront = React.lazy(() => import("./Components/Storefront"));

const MobileNavBar = React.lazy(() =>
  import("./Components/MobileScreens/Navbar")
);

const MobileDahsboard = React.lazy(() =>
  import("./Components/MobileScreens/mobileDashboard")
);

const AddSchedule = React.lazy(() =>
  import("./Components/MobileScreens/AddSchedule")
);

const AutoAddSlotMobile = React.lazy(() =>
  import("./Components/MobileScreens/AutoAddSlotMobile")
);

const DisableSlot = React.lazy(() =>
  import("./Components/MobileScreens/DisableSlot")
);
const viewHistory = React.lazy(() =>
  import("./Components/Glimpse/DetailedHistory")
);
const ViewStudentList = React.lazy(() =>
  import("./Components/Glimpse/ViewStudentList")
);

const Topics = React.lazy(() => import("./Components/Glimpse/Topics"));

const ChatWidget = React.lazy(() =>
  import("./Components/MobileScreens/ChatWidget")
);

const ProtectedRoute = () => {
  const roles = getRole();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const routes = [
    {
      path: "/demos",
      component: Demos,
      exact: false,
      allow: true,
    },
    {
      path: "/mentorDashboard",
      component: MentorDashboardNew,
      exact: false,
      allow: true,
    },
    {
      path: "/mobileDashboard",
      component: MobileDahsboard,
      exact: false,
      allow: true,
    },
    {
      path: "/mentorResources",
      component: MentorResources,
      exact: false,
      allow: true,
    },
    {
      path: "/affiliateDemos",
      component: AffiliateDemos,
      exact: false,
      allow: true,
    },
    {
      path: "/classes",
      component: Classes,
      exact: false,
      allow: true,
    },
    {
      path: "/timetable",
      component: TimeTable,
      exact: true,
      allow: true,
    },
    {
      path: "/mentors",
      component: Mentors,
      exact: true,
      allow: true,
    },
    {
      path: "/student-manage",
      component: StudentManageMent,
      exact: true,
      allow: true,
    },
    {
      path: "/mentor/view-class-details/:month/:year",
      component: ViewDetails,
      exact: true,
      allow: true,
    },
    {
      path: "/mentor/view-demo-details/:month/:year",
      component: ViewDetails,
      exact: true,
      allow: true,
    },
    {
      path: "/view-conversion/:month/:year",
      component: ViewConversion,
      exact: true,
      allow: true,
    },
    {
      path: "/glimpse",
      component: Glimpse,
      exact: true,
      allow: true,
    },
    {
      path: "/mentor-feedback/:classId",
      component: FeedbackForm,
      exact: true,
      allow: true,
    },
    {
      path: "/topics",
      component: Topics,
      exact: true,
      allow: true,
    },
    {
      path: "/view-history/:studentId/:courseId",
      component: viewHistory,
      exact: true,
      allow: true,
    },
    {
      path: "/view-student-list",
      component: ViewStudentList,
      exact: true,
      allow: true,
    },
    {
      path: "/add-schedule",
      component: AddSchedule,
      exact: true,
      allow: true,
    },
    {
      path: "/add-slot",
      component: AutoAddSlotMobile,
      exact: true,
      allow: true,
    },
    {
      path: "/disable-slot",
      component: DisableSlot,
      exact: true,
      allow: true,
    },
    {
      path: "/users",
      component: UserManagement,
      exact: true,
      allow: true,
    },
    {
      path: "/courses",
      component: Courses,
      exact: true,
      allow: true,
    },
    {
      path: "/promotions",
      component: Promotions,
      exact: true,
      allow: true,
    },
    {
      path: "/promotions/add-or-update-promotion",
      component: AddOrUpdatePromotions,
      exact: true,
      allow: true,
    },
    {
      path: "/slots",
      component: Slots,
      exact: false,
      allow: true,
    },
    { path: "/bootcamp", component: BootCamp, exact: false, allow: true },

    { path: "/alchemy", component: Alchemy, exact: false, allow: true },

    { path: "/community", component: Community, exact: false, allow: true },

    { path: "/storefront", component: StoreFront, exact: false, allow: true },
  ];
  return (
    <>
      {/* <div style={{ display: "flex" }}>
        <SideNavbar />
        <Suspense fallback={<Loader />}>
          <Routes routes={routes} redirectTo="/demos" />
        </Suspense>
      </div> */}
      {roles == "mentor" ? (
        <Grid container xs={12}>
          <Grid item container>
            <Suspense fallback={<Loader />}>
              {mobileScreen ? (
                <Grid item xs={12}>
                  <MobileNavBar />
                </Grid>
              ) : (
                <Grid item container xs={1}>
                  <SideNavigationBar />
                </Grid>
              )}
              <Grid xs={mobileScreen ? 12 : 11}>
                <Routes
                  routes={routes}
                  redirectTo={
                    roles === "affiliate_partner"
                      ? "/affiliateDemos"
                      : roles === "mentor"
                      ? "/mentorDashboard"
                      : "/demos"
                  }
                />
              </Grid>
            </Suspense>
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={12}>
          <Grid item container xs={2}>
            <SideNavbar />
          </Grid>
          <Grid item container xs={10}>
            <Suspense fallback={<Loader />}>
              <Routes
                routes={routes}
                redirectTo={
                  roles === "affiliate_partner"
                    ? "/affiliateDemos"
                    : roles === "mentor"
                    ? "/mentorDashboard"
                    : "/demos"
                }
              />
            </Suspense>
          </Grid>
        </Grid>
      )}
    </>
  );
};
class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/" component={Login} exact />
              <Route
                path="/studentPortfolio/:studentUrlPath"
                component={StudentPortfolio}
                exact
              />
              <Route
                path="/mentors/:mentorId"
                component={MentorProfilePage}
                exact
              />
              <Route path="/chatwithus" component={ChatWidget} exact />
              <Route
                path="/bootCampLandingPage/:bootcampName"
                component={BootCampLandingPage}
                exact
              />
              <Route
                path="/success-paymnet/:bootCampId"
                component={SuccessPayment}
                exact
              />
              <Route
                path="/spr/:studentId/:batchId/:courseId"
                component={StudentProfileReport}
                exact
              />
              <Route
                path="/assignAutoBatch/:courseId/:orderId"
                component={SelectBatch}
                exact
              />
              <Route
                path={`/alchemy/alchemyFilePreview/:id`}
                component={AlchemyFilePreview}
              />

              <Route path="/class/:meetingId" component={ZoomMeet} exact />
              <Route path="/leaveZoom" component={LeaveZoom} exact />
              <Route
                path={`/parentFeedback/:orderId/:scheduleId`}
                component={ParentFeedback}
                exact
              />
              <Route
                path="/parentFeedback/:orderId/:scheduleId/1"
                component={FirstClassFeedback}
              />
              <Route
                path={`/successParentFeedback`}
                component={SuccessFeedback}
              />
              <Authentication>
                <ProtectedRoute />
              </Authentication>
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Fragment>
    );
  }
}

export default App;
