// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
// import { determineAppServerKey } from "./utils/urlBase64ToUint8array";
import { API } from "./Services/api-services";

const firebaseConfig = {
  apiKey: "AIzaSyBSlA1sCNPbWMKrUvYl6mrHF7vaH5VUBGc",
  authDomain: "pwas-a42d3.firebaseapp.com",
  projectId: "pwas-a42d3",
  storageBucket: "pwas-a42d3.appspot.com",
  messagingSenderId: "254373538679",
  appId: "1:254373538679:web:377a1d37fc6a87620e8f72",
  measurementId: "G-5TELJ6HHX2",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// const messaging = getMessaging(firebaseApp);

// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     messaging
//       .requestPermission()
//       .then(() => messaging.getToken())
//       .then((firebaseToken) => {
//         resolve(firebaseToken);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });

const messaging = (async () => {
  try {
    if(window.reactNativeWebView){
      return;
    }
    const isSupportedBrowser = await isSupported();
    console.log(isSupportedBrowser, "supported");
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const requestPermission = async (handleNotificationActivation) => {
  if(window.reactNativeWebView){
    return;
  }
  // if (Notification in window) {
  let permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        return getToken(messagingResolve, {
          vapidKey:
            "BIR-8nS6tXp8u83QeFPee6xiA-eoLa_PccZ-A5rj7jAkekfVcKE8iHx4k9Jc2rTUsuFBcrJuOh7ACaJPKQ3h8UU",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("beforeinstallprompt" in window);
              handleNotificationActivation(true, currentToken);

              API.post("/fcm/user", {
                token: currentToken,
              })
                .then((res) => {
                  console.log("res", res);
                })
                .catch((e) => {
                  console.log(e);
                });

              // Track the token -> client mapping, by sending to backend server
              // show on the UI that permission is secured
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              handleNotificationActivation(false, "");
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  } else {
    console.log("Notification permission denied");
    handleNotificationActivation("denied", "");
  }
};

export const requestForToken = (handleNotificationActivation) => {
  return "";
};

export const onMessageListener = async () =>
  new Promise((resolve) => {
    (async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, (payload) => {
          console.log("payload", payload);
          resolve(payload);
        });
      }
    })();
  });

export default firebaseApp;
