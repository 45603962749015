export const reducer_insertPath = (data) => ({
  type: "INSERT_DATA",
  data,
});

export const reducer_removePath = (data) => ({
  type: "REMOVE_PATH",
  data,
});

export const reducer_clearBreadCrumbs = () => ({
  type: "CLEAR_PATH",
});
