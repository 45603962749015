import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  folderPath: [],
};

export const folderPathReducer = createReducer(initialState, {
  INSERT_DATA: (state, action) => {
    state.folderPath.push(action.data);
  },
  REMOVE_PATH: (state, action) => {
    let duplicateState = JSON.parse(JSON.stringify(state.folderPath));
    duplicateState.splice(action.data + 1);
    state.folderPath = duplicateState;
  },
  CLEAR_PATH: (state, action) => {
    state.folderPath = [];
  },
});
