import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visualAid: [],
  searchResource: "",
};

const searchResources = createSlice({
  name: "resources",
  initialState,
  reducers: {
    updatesearchResource: (state, actions) => {
      state.searchResource = actions.payload;
    },
  },
});

export default searchResources.reducer;
export const { updatesearchResource } = searchResources.actions;
