import React, { useState } from "react";
import BgBigScreen from "../../assets/images/first_class_desktop_v2.png";
import BgSmallScreen from "../../assets/images/first_class_mobile.png";
import { makeStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import { toast } from "react-toastify";
import { API } from "../../Services/api-services";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import codeyoung from "../../assets/images/primary_logo.png";

toast.configure();

const useStyles = makeStyles((theme) => ({
  outerBOx: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      background: "#FFFBFA",
      height: "100vh",
      overflowY: "scroll",
    },
  },
  logoBox: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    boxShadow: " 5px 4px 18px #888888",
  },
  logoImg: {
    width: "150px",
    height: "60px",
  },
  imgBox: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "20px",
    },
  },
  bgImgBigScreen: {
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  bgImgSmallScreen: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  questionBg: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#FFFBFA",
      width: "100%",
      paddingLeft: "60px",
    },
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  headingText: {
    color: "#444444",
    fontSize: "28px",
    fontWeight: "700",
    display: "block",
    marginTop: "30px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: { fontSize: "20px", textAlign: "center" },
  },
  subHeadingText: {
    color: "#555555",
    fontSize: "16px",
    display: "block",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
      letterSpacing: "1.2px",
    },
  },
  questionOuterBox: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  questionInnerBox: {
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  questionBox: {
    background: "#fff",
    width: "80%",
    padding: "4px 0px 16px 20px",
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0px 1px 8px rgba(129, 11, 11, 0.08)",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  questionBoxText: {
    fontSize: "18px",
    color: "#555555",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  radioBtn: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "40px",
    },
  },
  textArea: {
    width: "95%",
    border: "1px solid #FFBA99",
    borderRadius: "4px",
    padding: "5px",
    fontSize: "16px",
  },
  btnBOx: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  btn: {
    backgroundColor: "#FF712D",
    padding: "8px 126px",
    border: "none",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 96px",
    },
  },
}));

function FirstClassFeedback() {
  const classes = useStyles();
  let params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const options = ["Good", "Average", "Bad"];

  const [questionAnswers, setQuestionAnswers] = useState({
    firstClass: "",
    teacherAssigned: "",
    codeyoungPlatform: "",
    comment: "",
  });

  const onHandlerChange = (e, index) => {
    let updateValue = update(questionAnswers, {
      [e.target.name]: { $set: e.target.value },
    });
    setQuestionAnswers(updateValue);
  };

  const submitFeedback = () => {
    setLoading(true);

    if (
      questionAnswers.firstClass === "" ||
      questionAnswers.teacherAssigned === "" ||
      questionAnswers.codeyoungPlatform === ""
    ) {
      setLoading(false);
      toast.error("Please Fill All Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const response = {
        1018: questionAnswers.firstClass,
        1019: questionAnswers.teacherAssigned,
        1020: questionAnswers.codeyoungPlatform,
        1021: questionAnswers.comment,
      };

      API.post("/storeParentFeedback", {
        responses: response,
        orderId: params.orderId,
        scheduleId: params.scheduleId,
      })
        .then((res) => {
          setLoading(false);
          history.push("/successParentFeedback");
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    }
  };

  return (
    <div className={classes.outerBOx}>
      <div className={classes.logoBox}>
        <img src={codeyoung} alt="codeyoung" className={classes.logoImg} />
      </div>
      <div className={classes.imgBox}>
        <img src={BgBigScreen} className={classes.bgImgBigScreen} />
        <img src={BgSmallScreen} className={classes.bgImgSmallScreen} />
      </div>
      <div className={classes.questionBg}>
        <span className={classes.headingText}>
          How was your first class with Codeyoung?
        </span>

        <span className={classes.subHeadingText}>
          Please share feedback on your first class with us so we can improve
          your experience.
        </span>

        <div className={classes.questionOuterBox}>
          <div className={classes.questionInnerBox}>
            <div className={classes.questionBox}>
              <p className={classes.questionBoxText}>
                How was your first class with Codeyoung ?
              </p>
              {options.map((option) => {
                return (
                  <span className={classes.radioBtn}>
                    <input
                      type="radio"
                      id="firstClass"
                      name="firstClass"
                      value={option}
                      onChange={(e) => onHandlerChange(e)}
                    />
                    {"   "}
                    <span className={classes.questionBoxText}>{option}</span>
                  </span>
                );
              })}
            </div>

            <div className={classes.questionBox}>
              <p className={classes.questionBoxText}>
                How do you like the teacher assigned to you ?
              </p>
              {options.map((option) => {
                return (
                  <span className={classes.radioBtn}>
                    <input
                      type="radio"
                      id="teacherAssigned"
                      name="teacherAssigned"
                      value={option}
                      onChange={(e) => onHandlerChange(e)}
                    />
                    {"   "}
                    <span className={classes.questionBoxText}>{option}</span>
                  </span>
                );
              })}
            </div>

            <div className={classes.questionBox}>
              <p className={classes.questionBoxText}>
                How was the onboarding and introduction to Codeyoung platform ?
              </p>
              {options.map((option) => {
                return (
                  <span className={classes.radioBtn}>
                    <input
                      type="radio"
                      id="codeyoungPlatform"
                      name="codeyoungPlatform"
                      value={option}
                      onChange={(e) => onHandlerChange(e)}
                    />
                    {"   "}
                    <span className={classes.questionBoxText}>{option}</span>
                  </span>
                );
              })}
            </div>

            <div className={classes.questionBox}>
              <p className={classes.questionBoxText}>
                Please share any comments if there is anything you would like to
                bring to our attention
              </p>
              <textarea
                rows="6"
                className={classes.textArea}
                placeholder="Enter comments here..."
                name="comment"
                onChange={(e) => onHandlerChange(e)}
              />
            </div>
          </div>
        </div>

        <div className={classes.btnBOx}>
          <button className={classes.btn} onClick={submitFeedback}>
            {" "}
            {loading ? "LOADING..." : "Send Feedback"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FirstClassFeedback;
