import { createSlice } from "@reduxjs/toolkit";

const appDeviceSlice = createSlice({
  name: "appDevice",
  initialState: {
    token:localStorage.getItem("deviceToken"),
    linked: false,
  },
  reducers: {
    setAppDeviceData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAppDeviceData } = appDeviceSlice.actions;
export default appDeviceSlice.reducer;
