import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lottie from "react-lottie";
import close from "../../assets/images/close.png";
import Data from "./animation.json";

const useStyles = makeStyles({
  innerContainer: {
    width: "600px",
    height: "520px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 6,
    cursor: "pointer",
  },
});
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Data,
};

const AuthErrorModal = ({ open, closeModals }) => {
  const classes = useStyles();
  const closeModal = () => {
    console.log("qwerty");
    closeModals();
  };
  return (
    <Dialog open={open} maxWidth="lg">
      <div className={classes.innerContainer}>
        <div onClick={closeModal} className={classes.closeIcon}>
          <img src={close} alt="close" />
        </div>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </Dialog>
  );
};

export default AuthErrorModal;
