import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    setNotificationData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setNotificationData } = notificationSlice.actions;
export default notificationSlice.reducer;
