import React from "react";

import "./Loader.scss";

const Loader = () => {
  return (
    // <div className="loading">
    //   <div></div>
    //   <div></div>
    // </div>
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
