import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
// import swDev from "./swDev";
import { firebaseApp } from "./firebase";
import * as firebase from "firebase/app";

import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";

import { store } from "./Redux/store";

const firebaseConfig = {
  apiKey: "AIzaSyBSlA1sCNPbWMKrUvYl6mrHF7vaH5VUBGc",
  authDomain: "pwas-a42d3.firebaseapp.com",
  projectId: "pwas-a42d3",
  storageBucket: "pwas-a42d3.appspot.com",
  messagingSenderId: "254373538679",
  appId: "1:254373538679:web:377a1d37fc6a87620e8f72",
  measurementId: "G-5TELJ6HHX2",
};

firebase.initializeApp(firebaseConfig);

if ("serviceWorker" in navigator && !window.ReactNativeWebView) {
  let swUrl = `${process.env.REACT_APP_EPICENTER_URL}firebase-messaging-sw.js`;
  navigator.serviceWorker.register(swUrl).then((response) => {
    console.log("service worker registered", response);
    //   return response.pushManager.getSubscription().then((subscription) => {
    //     return response.pushManager.subscribe({
    //       userVisibleOnly: true,
    //       applicationServerKey:
    //         "BIR-8nS6tXp8u83QeFPee6xiA-eoLa_PccZ-A5rj7jAkekfVcKE8iHx4k9Jc2rTUsuFBcrJuOh7ACaJPKQ3h8UU",
    //     });
    //   });
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// swDev();
