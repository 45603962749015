/**
 *
 * @param {object to be encrypted} o
 * @param {string} salt
 */
export function encrypt(o, salt = "Salt Strirng") {
  o = JSON.stringify(o).split("");
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === "{") o[i] = "}";
    else if (o[i] === "}") o[i] = "{";
  return encodeURI(salt + o.join(""));
}

export function decrypt(o, salt = "Salt Strirng") {
  o = decodeURI(o);
  if (salt && o.indexOf(salt) !== 0) return false;
  o = o.substring(salt.length).split("");
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === "{") o[i] = "}";
    else if (o[i] === "}") o[i] = "{";
  return JSON.parse(o.join(""));
}

/**
 *
 * @param {Number} numberOfInstallments
 * @param {Array} paymentDetails
 */
export function installmentDetails(numberOfInstallments, paymentDetails) {
  let array = [];
  for (let i = 0; i < numberOfInstallments; i++) {
    array.push(paymentDetails[i]);
  }
  return array;
}

/**
 *
 * @param {String} property
 */
export function sortByProperty(property) {
  return function (a, b) {
    if (a[property] < b[property]) return 1;
    else if (a[property] > b[property]) return -1;

    return 0;
  };
}

/**
 * @description To generate uniqueId
 */
export function getUniqueId() {
  const length = 6;
  const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

/**
 * To add st, nd, rd.... after index
 */

export function ordinal_suffix_of(i) {
  var j = i % 10, // To get Last Digit
    k = i % 100; // To get 2 Digits
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}
